export const AuthActions = {
    Loading: 'LOADING',
    Login: 'LOGIN',
    Logout: 'LOGOUT',
    SelectTenant: 'SELECT-TENANT',
    SelectAccount: 'SELECT-ACCOUNT',
    UpdateAttributes: 'UPDATE-ATTRIBUTES',
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case AuthActions.Loading:
            return {
                ...state,
                isLoading: action.payload,
            };
        case AuthActions.Login:
            return {
                ...state,
                isLoading: false,
                attributes: action.payload,
                isAuthenticated: !!action.payload?.user,
                locale: action.payload?.user?.locale ?? null,
                allPermissions: [
                    ...new Set(
                        action.payload?.permissions
                            ?.map((account) => account.permissions)
                            .flat(1)
                    ),
                ],
                currentPermissions: action.payload?.permissions?.find(
                    (permission) =>
                        permission.umoAccountIdentifier ===
                        action.payload?.account_id
                )?.permissions,
                isTenantSelected:
                    action.payload?.selected_tenant ||
                    !!action.payload?.tenants?.length > 1,
            };
        case AuthActions.Logout:
            return {
                ...state,
                isLoading: false,
                attributes: null,
                isAuthenticated: false,
                isTenantSelected: false,
            };
        case AuthActions.SelectTenant:
            return {
                ...state,
                isLoading: false,
                isTenantSelected: true,
            };
        case AuthActions.SelectAccount:
            return {
                ...state,
                accountId: action.payload,
                currentPermissions: state.attributes?.permissions?.find(
                    (permission) => `${permission.id}` === action.payload
                )?.permissions,
            };
        case AuthActions.UpdateAttributes:
            return {
                ...state,
                attributes: {
                    ...state.attributes,
                    ...action.payload,
                },
            };
    }
};
